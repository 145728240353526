import request from '../axios/request';

//Service to get list of Assets
export const getAssetsService = (params = null) => {
  	return async (dispatch, getState) => {
         const sessionID = JSON.parse(localStorage.getItem("SessionID"));

    	return dispatch(
			request('/api/DataAccessServices/Live/Assets/List/' + sessionID, getState(), {
				method: 'GET',
				params,
			}),
		);
	};
};

