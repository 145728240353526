import React, { useEffect,useState } from 'react';

import { useDispatch, useSelector} from 'react-redux';
import 'antd/dist/antd.min.css';
import { Row, Col } from 'antd';
import LeftPanel from './components/LeftPanel/LeftPanel';
import RightPanel from './components/RightPanel/RightPanel';
import {authenticate} from './api/redux/action/AuthActions';
import {fetchAssets} from './api/redux/action/AssetsActions';
import './App.css';

let sse;

function App() {
  const dispatch = useDispatch();

  const [event, setEvent]  = useState(null);

  const {
    sessionID,
    assets
  } = useSelector((store) => ({
    sessionID: store.authReducer.sessionID,
    assets:store.assetsReducer.assets
  }));

  //Authenticate user on reload
  useEffect(()=>{
      dispatch(authenticate());
  },[]);

  //If Session changes or is available after authentication, get list of Assets
  useEffect(()=>{
    if(sessionID){
      dispatch(fetchAssets());
    }
  }, [sessionID])

  //if assets array changes, add markers to map
  useEffect(()=>{
    if(sse){

    }else{
      const url = 'https://lats.handsonsystems.com/lats/session/' + sessionID  + '/assets/events?type=location';
      sse = new EventSource(url, { withCredentials: false });

      sse.onmessage = (e) => {
        setEvent(JSON.parse(e.data));
      }; 

      sse.onerror = (e) => {
        closeSSE(e);
      };
    }
  }, [assets])

//Adding events when closing or refreshing the tab
  useEffect(() => {
    window.addEventListener('beforeunload', closeSSE)
   
    return () => {
        window.removeEventListener('beforeunload', closeSSE)
    }
  })

  //Close sse event when refreshing or closing the tab
  const closeSSE = (event) => {
    sse.close();
  }

  return (
    <>
      <Row className='wrapper'>
        <Col xs={24} sm={24} md={12} lg={7} xl={7} className={"left-side"}>
          <LeftPanel/>
        </Col>
        <Col xs={24} sm={24} md={12} lg={17} xl={17} className={"right-side"}>
         <RightPanel assets={assets} event={event}/>
        </Col>
      </Row>
    </>
  );
}

export default App;