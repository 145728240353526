import * as type from "../types";
import { RequestState } from "./RequestState";

const INITIAL_STATE = {
  sessionID: JSON.parse(localStorage.getItem("SessionID")),
  user: null,
  error: null,
  requestStatus: RequestState.EMPTY,
  isUnauth: true
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case type.AUTH_REQUEST:
        return {
          ...state,
          error: null,
          requestStatus: RequestState.PENDING,
        };
      case type.AUTH_SUCCESS:
        localStorage.setItem("SessionID", JSON.stringify(action.payload.M_SessionID));
        return {
          ...state,
          sessionID: action.payload.M_SessionID,
          user: action.payload,
          error: null,
          requestStatus: RequestState.SUCCESS,
          isUnauth: false,
        };
      case type.AUTH_FAILURE:
        return {
          ...state,
          sessionID: null,
          user:null,
          error: action.payload,
          requestStatus: RequestState.FAILURE,
        };
      case type.UNAUTHORIZED:
        return {
          ...state,
          isUnauth: true,
          sessionID: null,
          user:null,
          error: action.type,
          requestStatus: RequestState.FAILURE,
        };
      case type.CLEAN_REQUEST_STATUS:
        return {
          ...state,
          error: action.payload,
          requestStatus: RequestState.FAILURE,
        };
      default:
        return state;
    }
  };
  
  export default reducer;
  