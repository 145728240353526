import {Row } from 'antd';
import LOGO from '../../../assets/handson_white_logo.png';
import './Logo.css';

const Logo = ()=>{
    return (
        <Row>
            <div className="logo">
                <a href="https://www.handsonsystems.com/" >
                    <img src={LOGO} className="logo-img" alt="logo" />
                </a>
                 <div className='title'>LIVE TRACKING</div>
            </div>
        </Row>
    )
}

export default Logo;