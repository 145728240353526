import * as axios from 'axios';

//Setting base url for all API calls
const host = 'https://fleet.handsonsystems.com';

const request = (url, reduxState, options) => {
  	return async () => {
    	const config = {
      		url: host + url,
      		...options,
      		withCredentials: false,
      		headers: {
				'content-type': 'application/json',
			 },
				                    
		};

    	let response;
    
		try {
      		response = axios(config);
    	} catch (error) {
      		console.error(error);
    	}
    	return response;
  	};
};

export default request;
