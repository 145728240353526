import { ResponseStatus } from '../../network/axios/ResponseStatus';
import { UNAUTHORIZED } from '../types';

const removeSessionIDFromLocalStorage=(name)=>{
  localStorage.removeItem(name);
}


//If the user is aunaothorized the session ID is removed from the localstorage and prompted to log in again
export default function dispatchStateIfUnauthorized(ex, dispatch) {
  if (ex.response  && ex.response.status === ResponseStatus.UNAUTHORIZED) {
    removeSessionIDFromLocalStorage('SessionID');
    dispatch({
      type: UNAUTHORIZED,
    });
  }
}
