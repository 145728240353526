//Authentication
export const UNAUTHORIZED = "UNAUTHORIZED";
export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";

//Assets
export const ASSETS_FETCH_REQUEST = "ASSETS_FETCH_REQUEST";
export const ASSETS_FETCH_SUCCESS = "ASSETS_FETCH_SUCCESS";
export const ASSETS_FETCH_FAILURE = "ASSETS_FETCH_FAILURE";

export const CLEAN_REQUEST_STATUS = "CLEAN_REQUEST_STATUS";