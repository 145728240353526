import * as type from '../types';
import {
  getAssetsService
} from '../../network/service/AssetsService';
import { ResponseStatus } from '../../network/axios/ResponseStatus';
import dispatchStateIfUnauthorized from './util';

//Fetch Assets action
export const fetchAssets = (params = null) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: type.ASSETS_FETCH_REQUEST,
      });
      const response = await dispatch(getAssetsService(params));
      if (response.status === ResponseStatus.OK) {
        dispatch({
          type: type.ASSETS_FETCH_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: type.ASSETS_FETCH_FAILURE,
        });
      }
    } catch (ex) {
      dispatchStateIfUnauthorized(ex, dispatch);
      dispatch({
        type: type.ASSETS_FETCH_FAILURE,
      });
    }
  };
};

