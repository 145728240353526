import * as type from "../types";
import { RequestState } from "./RequestState";

const INITIAL_STATE = {
  assets: null,
  error: null,
  requestStatus: RequestState.EMPTY
};


const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case type.ASSETS_FETCH_REQUEST:
        return {
          ...state,
          assets:null,
          error: null,
        };
      case type.ASSETS_FETCH_SUCCESS:
        return {
            ...state,
            assets: action.payload.Rows,
        };
      case type.ASSETS_FETCH_FAILURE:
        return {
            ...state,
            assets:null,
        };
        default:
            return state;
    }
};
  
export default reducer;
    